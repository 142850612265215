import React from 'react'

const Image = props => (
  <svg
    width="390"
    height="420"
    viewBox="0 0 390 420"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ maxWidth: '100%' }}
  >
    <path
      d="M152.5 420C236.723 420 305 351.622 305 267.273C305 182.924 152.5 0 152.5 0C152.5 0 0 182.924 0 267.273C0 351.622 68.2766 420 152.5 420Z"
      fill="#0099FC"
      fillOpacity="0.25"
    />
    <text
      fill="#043E86"
      xmlSpace="preserve"
      // style="white-space: pre"
      fontFamily="VentiCF"
      fontSize="28"
      letterSpacing="0px"
      fontWeight="bold"
    >
      <tspan x="122.328" y="208.707">
        2019
      </tspan>
    </text>
    <text
      fill="#0099FC"
      xmlSpace="preserve"
      // style="white-space: pre"
      fontFamily="VentiCF"
      fontSize="54"
      letterSpacing="0px"
      fontWeight="bold"
    >
      <tspan x="84.3682" y="285.721">
        2 709
      </tspan>
    </text>
    <path
      d="M317 232C357.317 232 390 199.439 390 159.273C390 119.107 317 32 317 32C317 32 244 119.107 244 159.273C244 199.439 276.683 232 317 232Z"
      fill="#E5E5E5"
    />
    <text
      opacity="0.75"
      fill="#4A4A4A"
      xmlSpace="preserve"
      // style="white-space: pre"
      fontFamily="VentiCF"
      fontSize="28"
      letterSpacing="0px"
      fontWeight="bold"
    >
      <tspan x="286.328" y="112.707">
        1989
      </tspan>
    </text>
    <text
      fill="#4A4A4A"
      xmlSpace="preserve"
      // style="white-space: pre"
      fontFamily="VentiCF"
      fontSize="54"
      letterSpacing="0px"
      fontWeight="bold"
    >
      <tspan x="272.412" y="189.721">
        604
      </tspan>
    </text>
  </svg>
)

export default Image
