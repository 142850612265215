import React from 'react'
import {
  Row,
  Col,
  Gap,
  Text,
  SEO,
  Title,
  Container,
  FaqSection,
  ArticlesSection,
  QualityMeasureSection,
  TapsBg,
  Tooltip,
} from '../components'

import { QualityGraph } from '../components/graphs'

import DropsCleaningImage from '../components/images/DropsCleaning'

class Page extends React.Component {
  render() {
    const {
      location: { pathname },
    } = this.props

    return (
      <>
        <SEO pathname={pathname} title="Kvalita vody" />
        <Gap.Top />
        <Container>
          <Col width="100%" justifyContent="center" alignItems="center">
            <Title.Main>Kvalita vody</Title.Main>
          </Col>
          <Gap gap="185px" />

          <Col
            width="100%"
            alignItems="stretch"
            mobileAlignItems="center"
            bp="tablet"
          >
            <Col.Text>
              <Title>Pitná voda</Title>
            </Col.Text>
            <Gap.Title />
            <Row justifyContent="space-between" responsive bp="tablet">
              <Col grow={1}>
                <Title.Sub>
                  Jak poznat
                  <strong> pitnou vodu? </strong>
                </Title.Sub>
              </Col>
              <Gap.Title />
              <Col.Text>
                <Text>
                  Jedná se o vodu s takovými vlastnostmi, kterou můžeme požívat
                  dlouhodobě, aniž by měla negativní dopady na zdraví. Je také
                  nutné, aby splnila{' '}
                  <Tooltip content="Vyhláška vymezuje dva pojmy: mezní hodnotu a nejvyšší mezní hodnotu. Překročení mezní hodnoty zpravidla nepředstavuje akutní zdravotní riziko; v případě překročení nejvyšší mezní hodnoty se jedná o závažný problém. Voda pak nemůže být označena jako „pitná“ a její konzumace může mít závažné důsledky.">
                    hygienické limity
                  </Tooltip>{' '}
                  včetně mikrobiologických, biologických, fyzikálních,
                  chemických, vzhledových a chuťových ukazatelů.
                </Text>
                <Gap.Paragraph />
                <Text>
                  Kvalitu pitné vody u nás upravuje zákon č. 258/2000 Sb. (Zákon
                  o ochraně veřejného zdraví) a dále upřesňuje vyhláška č.
                  252/2004 Sb., která se věnuje čistě jen pitné vodě.
                </Text>
              </Col.Text>
            </Row>

            <Gap gap="110px" mobileGap="80px" bp="tablet" />

            <Row justifyContent="space-between" responsive bp="tablet">
              <Col grow={1} maxWidth="570px">
                <Title.Sub>
                  Vývoj četnosti překročení nejvyšší mezní hodnoty
                </Title.Sub>
              </Col>
              <Gap.Title />
              <Col.Text>
                <Text>
                  Voda v České republice si dlouhodobě udržuje dobrou kvalitu a
                  pohybuje se ve stanovených normách. To dokládají i kontroly
                  jednak samotných vodáren, ale také{' '}
                  <Tooltip content="Státního zdravotního ústavu, příspěvkovou organizací Ministerstva zdravotnictví ČR">
                    SZÚ
                  </Tooltip>
                  , které probíhají několikrát ročně. Jejich četnost se liší{' '}
                  <Tooltip content="Například na vodovodu, který zásobuje 100 000 obyvatel, bývá provedeno až 24 kontrol ročně.">
                    podle velikosti oblasti.
                  </Tooltip>
                </Text>
                <Gap.Paragraph />
                <Text>
                  <strong>Nejvyšší mezní hodnota </strong>
                  je závažný ukazatel jakosti pitné vody, v důsledku jehož
                  překročení je vyloučeno použití vody jako pitné.
                </Text>
              </Col.Text>
            </Row>
          </Col>
        </Container>

        <Col position="relative" alignItems="center" width="100%">
          <TapsBg transparent right="0" top="400px" xCount={4} yCount={2} />
          <Container>
            <Col width="100%" justifyContent="center">
              <Gap gap="70px" />
              <QualityGraph />
              <Gap gap="120px" />
            </Col>
          </Container>
        </Col>

        <Container>
          <Col width="100%" alignItems="center">
            <Title.Sub textAlign="center">Jak měříme kvalitu vody?</Title.Sub>
            <Gap.Title />
            <Text textAlign="center">
              Pro určení nezávadnosti a kvality vody se používá několik
              kritérií. Mezi ně patří:
            </Text>
            <Gap gap="40px" />
            <QualityMeasureSection />
          </Col>
        </Container>

        <Col width="100%" maxWidth="1430px" alignItems="center">
          <Gap gap="40px" />
          <Row position="relative" width="100%">
            <TapsBg yCount={1} transparent left="0" />
          </Row>
          <Gap gap="150px" />
        </Col>

        <Gap gap="100px" mobileGap="60px" bp="tablet" />

        <Container>
          <Col
            width="100%"
            alignItems="stretch"
            mobileAlignItems="center"
            bp="tablet"
          >
            <Col.Text>
              <Title>Odpadní voda</Title>
            </Col.Text>
            <Gap.Title />
            <Row justifyContent="space-between" responsive bp="tablet">
              <Col.Text>
                <Title.Sub>
                  Co musí splňovat
                  <strong> vyčištěná </strong>
                  odpadní voda, než je z čistírny odpadních vod vypuštěna zpět
                  do vodního toku?
                </Title.Sub>
                <Gap.Title />
                <Text>
                  Zákon říká, že odpadní vodu definuje zhoršená kvalita vlivem
                  lidské činnosti. Tuto definici splňuje například splašková
                  voda z domácností, ale také voda znečištěná průmyslovou
                  činností. Před vypuštěním musí projít čisticím procesem a
                  splňovat emisní limity. Záleží přitom na ukazatelích jako
                  dusík, fosfor, nerozpuštěné látky, chemická a biochemická
                  spotřeba kyslíku.
                </Text>
              </Col.Text>
              <Gap.Col />
              <Col grow={1} alignItems="center">
                <Text.HighLight textAlign="center" maxWidth="290px">
                  Počet čistíren odpadních vod v České republice
                </Text.HighLight>
                <Gap gap="34px" />
                <DropsCleaningImage />
              </Col>
            </Row>
          </Col>

          <Gap gap="75px" mobileGap="60px" bp="tablet" />
        </Container>

        <ArticlesSection page="QUALITY" />

        <FaqSection page="QUALITY" />

        <Gap gap="230px" />
      </>
    )
  }
}

export default Page
